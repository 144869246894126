<template>
  <v-form>
     <v-select
        :label="$t('expirationMonth')"
        filled
        :items="months"
        item-value="key"
        item-text="value"
        v-model="expiryMonth"
    />
    <v-select
        :label="$t('expirationYear')"
        filled
        :items="years"
        v-model="expiryYear"
    />
  </v-form>
</template>

<script>
export default {
  name: "UpdateGiftCard",
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      expiryMonth: null,
      expiryYear: null,
    }
  },
  computed: {
    years() {
        return ['2022','2023', '2024', '2025', '2026', '2027', '2028']
    },
    months() {
        return [
            {
                key: '01',
                value: this.$t('january'),
            },
            {
                key: '02',
                value: this.$t('february'),
            },
            {
                key: '03',
                value: this.$t('march'),
            },
            {
                key: '04',
                value: this.$t('april'),
            },
            {
                key: '05',
                value: this.$t('may'),
            },
            {
                key: '06',
                value: this.$t('june'),
            },
            {
                key: '07',
                value: this.$t('july'),
            },
            {
                key: '08',
                value: this.$t('august'),
            },
            {
                key: '09',
                value: this.$t('september'),
            },
            {
                key: '10',
                value: this.$t('october'),
            },
            {
                key: '11',
                value: this.$t('november'),
            },
            {
                key: '12',
                value: this.$t('december'),
            }
        ]
    }
  },
  watch: {
    expiryMonth(value) {
      this.$emit('input', { uuid: this.value.uuid, expiryMonth: value, expiryYear: this.value.expiryYear }) // this value will have to change when updating to vue 3
      this.$emit('valid', true)
    },
    expiryYear(value) {
      this.$emit('input', { uuid: this.value.uuid, expiryMonth: this.value.expiryMonth, expiryYear: value }) // this value will have to change when updating to vue 3
      this.$emit('valid', true)
    }
  },
  mounted() {
    const expiryMonthString = this.value.expiryMonth.toString().length > 1 ? this.value.expiryMonth.toString() : '0' + this.value.expiryMonth.toString()

    this.expiryMonth = this.months.find(v => v.key === expiryMonthString).key
    this.expiryYear = this.value.expiryYear.toString()
  }
}
</script>

<style scoped>

</style>